<template>
  <div class="loginInput">
    <img class="icon" :src="require(`@/assets/images/login/${$attrs.icon}`)" />
    <input
      :maxlength="maxlength"
      v-bind="$attrs"
      v-on="{
        ...$listeners,
        input: (event) => $emit('input', event.target.value),
      }"
    />
    <img v-if="$attrs.value.length > 0" class="icon" src="@/assets/images/login/ic_payment_closed.png" @click="clean" />
    <button v-if="$attrs.innerBtn" class="innerBtn" @click="getSMScode">
      获取验证码
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "LoginInput",
  props: {
    userMobile: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 1000,
    },
  },
  methods: {
    ...mapActions("auth", ["getLoginSMSCode"]),
    clean() {
      this.$emit("input", "");
    },
    getSMScode() {
      this.getLoginSMSCode(this.userMobile).then((res) => {
        if (res.code === "ok") {
          console.log(res);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.loginInput {
  width: 100%;
  height: 3rem;
  padding-bottom: 0.1rem;
  border-bottom: 0.5px solid var(--light-gray);
  display: flex;
  align-items: center;
  .icon {
    height: 1.5rem;
  }
  input {
    flex: auto 1 0;
    width: 50%;
    font-size: 1.2rem;
    border: none;
    margin-left: 0.3rem;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: var(--gray);
    }
  }
  .innerBtn {
    flex: 6.5rem 0 0;
    margin-left: 0.5rem;
    border: none;
    border-radius: 5px;
    height: 100%;
    color: var(--orange);
    font-size: 1.1rem;
    font-weight: 600;
    &:active {
      background-color: var(--pink);
    }
  }
}
</style>
