<template>
  <div class="register">
    <div class="back" @click="$router.push('/')">
      <img class="icon" src="@/assets/images/login/ic_common_back.webp" />
    </div>
    <div style="display:flex;position: relative;width:100%;	justify-content: center;">
      <a target="_blank" :href="customerServiceUrl">
        <div style="position:absolute;right:0;bottom:0">
          <img style="width:60px;height:60px" src="../../assets/images/login/ic_cloudpay_service.svg" />
          <p>云支付客服</p>
        </div>
      </a>
      <img class="logo" src="../../assets/images/login/ic_log_in_logo.webp" />
    </div>
    <div style="font-size: 22px;font-weight: bold;margin-top: 40px;">绑定手机号</div>
    <div class="inputArea">
      <login-input type="text" icon="ic_runningpoints_mine.webp" placeholder="请输入手机号" v-model="userMobile" />
      <login-input v-if="isPasswordLogin" type="password" icon="ic_runningpoints_password.webp" placeholder="请输入密码" v-model="password" />
      <div v-else class="flex-sb-c">
        <login-input type="password" icon="ic_runningpoints_verificationcode.png" placeholder="请输入验证码" v-model="verificationCode" />
        <button class="innerBtn" @click="getCode()">获取验证码</button>
      </div>
    </div>
    <div class="textBtn ta_left" @click="changeMode">
      {{ changeModeLabel }}
    </div>
    <button class="btn oval mb_1rem" @click="goRegister()">确定</button>
  </div>
</template>

<script>
import LoginInput from "@/components/LoginInput";
import { Message } from "element-ui";
import { mapActions } from "vuex";
import cookie from "js-cookie";
import { getUserConfig } from "@/api/config.js";

export default {
  name: "register",
  components: {
    LoginInput,
  },
  data() {
    return {
      customerServiceUrl: "",
      userMobile: "",
      password: "",
      verificationCode: "",
      loginMode: false, // 1:密码登录 0:验证码登录
    };
  },
  computed: {
    title() {
      return this.$route.meta.name;
    },
    isPasswordLogin() {
      return !!this.loginMode;
    },
    changeModeLabel() {
      return !this.loginMode ? "用密码绑定" : "用验证码绑定";
    },
  },
  watch: {},
  methods: {
    ...mapActions("register", ["postVisitorRegister"]),
    ...mapActions("sms", ["getSmsVisitorRegisterCode"]),
    back() {
      this.$router.go(-1);
    },
    changeMode() {
      this.loginMode = !this.loginMode;
    },
    goRegister() {
      if (this.userMobile.length != 11) {
        Message({
          message: "请输入11位手机号",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else if (this.verificationCode.length != 6 && this.isPasswordLogin == false) {
        Message({
          message: "短信验证码为6位数的数字",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else {
        let data;
        if (this.isPasswordLogin == true) {
          data = {
            userMobile: this.userMobile,
            password: this.password,
          };
        } else {
          data = {
            userMobile: this.userMobile,
            verifyCode: this.verificationCode,
          };
        }

        this.postVisitorRegister(data).then((res) => {
          if (res.code == "ok") {
            cookie.set("token", res.msg);
            if (cookie.get("orderNo")) {
              this.$router.push({ name: "my", query: { orderNo: cookie.get("orderNo") } });
            } else {
              this.$router.push("/");
            }
          } else {
            Message({
              message: res.msg,
              iconClass: "x",
              center: true,
              customClass: "error_message",
            });
          }
        });
      }
    },
    getCode() {
      let data = {
        userMobile: this.userMobile,
      };
      this.getSmsVisitorRegisterCode(data);
    },
    callMessage() {},
  },
  async created() {
    var res = await getUserConfig();
    this.customerServiceUrl = res.data.customerServiceUrl;
  },
  mounted() {},
  destroyed() {
    cookie.remove("orderNo");
  },
};
</script>

<style lang="scss" scoped>
.register {
  // background-image: url("../../assets/images/login/ic_log_in_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 1.2em 1.2em 1.2rem 1.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;

  @media (min-width: 480px) {
    padding: 1.2em 7.2em 1.2rem 7.2em;
  }

  .back {
    width: 100%;
    text-align: left;

    .icon {
      width: 2rem;
      height: auto;
    }
  }

  .logo {
    width: 7em;
    height: auto;
  }

  .inputArea {
    width: 100%;
    padding-top: 20%;
    margin-bottom: 1rem;

    @media (min-width: 480px) {
      padding-top: 0%;
      margin-bottom: 0rem;
    }
  }

  .innerBtn {
    flex: 6.5rem 0 0;
    margin-left: 0.5rem;
    border: none;
    border-radius: 5px;
    height: 100%;
    color: var(--orange);
    font-size: 1.1rem;
    font-weight: 600;

    &:active {
      background-color: var(--pink);
    }
  }

  .registerBtn {
    width: 100%;
    color: var(--black);
    font-size: 0.8rem;
  }

  .dialog_ {
    &header {
      font-size: 0.9em;
    }

    &title {
      font-size: 1em;
      font-weight: 600;
    }

    &msg {
      font-size: 0.8em;
    }
  }
}

.textBtn {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
